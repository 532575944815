import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {compose, withState} from 'recompose'
import {responsive} from '../../core/style'
import {injectGlobal} from 'emotion'
import DesktopSize from './DesktopSize'
import ResponsiveSize from './ResponsiveSize'
import {useScrollPosition} from './userScrollPosition'
import logo from '../../static/images/tqm-life/tqm_life_thai@2x.png'

injectGlobal`
 .ant-popover{
  position:fixed;
 }
  .ant-popover-arrow{
    display: none;
  }

 .ant-popover-inner {
  border-radius: 6px;
    div > .ant-popover-inner-content {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #265fa7;
      font-size: 16px;
      color: white;
      padding: 0;
      overflow: hidden;

      .userIcon{
        width: 142px;
      }

      .bellIcon{
        width: 300px;
        height: 150px;
        overflow: overlay;
        padding-top: 5px;
      }

      div{
       width:100%;
         .profile,.logout,.notificationContent{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 50px;
            cursor:pointer;

            span{
              opacity: 0.5;
            }

            :hover{
               background-color: #6A94CB;
               color:white;

               span{
                 opacity: 1;
               }
            }
          }
          .notificationContent{
            height: 60px;

            span{
              opacity: 1;
            }

            :hover{
              background-color: #6A94CB;
            }
          }
      }
    }
  }
`
const Container = styled.header`
  display: ${props => (props.isNoHeader ? 'none' : 'flex')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  align-items: center;
  justify-content: space-between;
  z-index: 999;

  background-color: ${props =>
    props.noOpacityHeader
      ? 'rgba(255, 255, 255, 1)'
      : 'rgba(255, 255, 255, 1)'}; //0.8
  color: black;
  transition: 0.5s;

  flex-direction: column;
  ${props =>
    responsive.mobile(css`
      top: ${props.top};
    `)};
`
const NavbarBase = styled.nav`
  line-height: 1;
  height: 80px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 80px;
  padding: 0px;
  @media (max-width: 425px) {
    margin-top: 0px;
  }
`
const WrapFixHeader = styled(NavbarBase)``
const FixedHeader = styled(WrapFixHeader)`
  display: none;
  visibility: visible;
  transition: all 200ms ease-in;
  @media (max-width: 425px) {
    transition: all 200ms ${props => (props.show ? 'ease-in' : 'ease-out')};
    transform: ${props => (props.show ? 'none' : 'translate(0, -100%)')};
  }
`

const WrapHeader = styled.a`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 8;
`
const CardHeader = styled.div`
  display: flex;
  position: sticky;
  /* margin-top: 160px; */
  top: 80px;
  z-index: 8;

  ${props =>
    responsive.mobile(css`
      > div {
        margin-top: unset;
      }
    `)};
`

const Logo = styled.img`
  width: 95px;
`
const Header = ({
  isLogin,
  top,
  isOnTop,
  noOpacityHeader,
  isNoHeader,
  className,
  hideOnScroll,
  setHideOnScroll,
}) => {
  useScrollPosition(
    ({prevPos, currPos}) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll],
    null,
    false,
    300,
  )
  return (
    <Container
      className={className}
      id="header"
      isOnTop={isOnTop}
      noOpacityHeader={noOpacityHeader}
      isNoHeader={isNoHeader}>
      {/* <DesktopSize menus={menus} />
      <ResponsiveSize menus={menus} />
      <FixedHeader id="auto-header-1" show={hideOnScroll} /> */}
      <WrapHeader href="https://www.tqm.co.th">
        <Logo src={logo} />
      </WrapHeader>
      <CardHeader id="auto-header" />
    </Container>
  )
}

const enhancer = compose(
  connect(
    (state, props) => ({
      isNoHeader: state.notFound.isNoHeader,
    }),
    {},
  ),
  withState('hideOnScroll', 'setHideOnScroll', true),
)

export default enhancer(Header)
